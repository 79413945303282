var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { generateFilterStatClientResponse } from '../../../utils';
import axios from '../../../utils/axios';
export var createStatClientResponse = createAsyncThunk('STAT_CLIENT_RESPONSE/POST', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, body, response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                body = payload.body;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.post('/stat-client-responses', body)];
            case 2:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_1 = _a.sent();
                return [2 /*return*/, Promise.reject(err_1.message ? err_1.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
// get all
export var getAllStatClientResponses = createAsyncThunk('STAT_CLIENT_RESPONSE/GET-ALL', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var page, _a, order, _b, orderBy, filterClientName, limit, filterStatClient, params, data, response, err_2;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                page = payload.page, _a = payload.order, order = _a === void 0 ? 'desc' : _a, _b = payload.orderBy, orderBy = _b === void 0 ? 'createdAt' : _b, filterClientName = payload.filterClientName, limit = payload.limit, filterStatClient = payload.filterStatClient;
                params = __assign(__assign({ page: page + 1, limit: limit, sort: order === 'desc' ? "-".concat(orderBy) : orderBy }, (filterClientName ? { clientName: filterClientName } : {})), (filterStatClient ? { statClient: filterStatClient } : {}));
                _c.label = 1;
            case 1:
                _c.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.get('/stat-client-responses', { params: params })];
            case 2:
                response = _c.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_2 = _c.sent();
                return [2 /*return*/, Promise.reject(err_2.message ? err_2.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
// get one
export var getOneStatClientResponse = createAsyncThunk('STAT_CLIENT_RESPONSE/GET-ONE', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var statClientResponseId, data, response, err_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                statClientResponseId = payload.statClientResponseId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.get("/stat-client-responses/".concat(statClientResponseId))];
            case 2:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_3 = _a.sent();
                return [2 /*return*/, Promise.reject(err_3.message ? err_3.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
// edit
export var editStatClientResponse = createAsyncThunk('STAT_CLIENT_RESPONSE/EDIT', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var statClientResponseId, body, data, response, err_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                statClientResponseId = payload.statClientResponseId, body = payload.body;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.patch("/stat-client-responses/".concat(statClientResponseId), body)];
            case 2:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_4 = _a.sent();
                return [2 /*return*/, Promise.reject(err_4.message ? err_4.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
// delete one
export var deleteStatClientResponse = createAsyncThunk('STAT_CLIENT_RESPONSE/DELETE', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var statClientResponseId, data, response, err_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                statClientResponseId = payload.statClientResponseId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.delete("/stat-client-responses/".concat(statClientResponseId))];
            case 2:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_5 = _a.sent();
                return [2 /*return*/, Promise.reject(err_5.message ? err_5.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
// delete many
export var deleteManyStatClientResponse = createAsyncThunk('STAT_CLIENT_RESPONSE/DELETE-MANY', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var statClientResponses, data, response, err_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                statClientResponses = payload.statClientResponses;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.delete("/stat-client-responses/delete", {
                        data: {
                            statClientResponseIds: statClientResponses,
                        },
                    })];
            case 2:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_6 = _a.sent();
                return [2 /*return*/, Promise.reject(err_6.message ? err_6.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var statsClientResponseFilter = createAsyncThunk('STAT_CLIENT_RESPONSE/FILTER', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var page, limit, filterValue, filterStatClient, order, orderBy, data, params, query, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                page = payload.page, limit = payload.limit, filterValue = payload.filterValue, filterStatClient = payload.filterStatClient, order = payload.order, orderBy = payload.orderBy;
                params = __assign(__assign({}, (filterStatClient ? { statClient: filterStatClient } : {})), { sort: order === 'desc' ? "-".concat(orderBy) : orderBy });
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                query = generateFilterStatClientResponse(filterValue, limit, page);
                return [4 /*yield*/, axios.get("/stat-client-responses/filter?".concat(query), { params: params })];
            case 2:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data.data];
                }
                throw new Error(response.statusText);
            case 3:
                error_1 = _a.sent();
                return [2 /*return*/, Promise.reject((error_1 === null || error_1 === void 0 ? void 0 : error_1.message) ? error_1.message : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
